import axios from 'axios';
import JWTAuth from "./jwt";

export async function getFASToken(email, task) {
  try {
    const auth = new JWTAuth()
    const token = await auth.createToken(email, task)
    return { token };
  } catch (error) {
    return { message: 'Something went wrong', error };
  }
}

export async function fasEnroll(email, privKey, fasToken) {
  return ;
  try {
    const { data } = await axios.post(
      `https://litewallet.dev2.cryptomailsvc.io/fasEnroll`,
      { email, privKey, fasToken },
    );
    return data;
  } catch (error) {
    return { message: 'Something went wrong', error };
  }
}
