import { Layout } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FASClient from '../components/FASClient';

const { Content } = Layout;

const FAS = () => {
  const fasClient = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [task, setTask] = useState(searchParams.get('task'));
  const [email, setEmail] = useState(searchParams.get('email'));
  const [token, setToken] = useState(searchParams.get('token'));

  useEffect(() => {
    console.log({ task, email, token });
  }, [searchParams]);

  useEffect(() => {
    if (task && email && token) {
      console.log('Loading fas');
      if (fasClient.current) {
        fasClient.current.load();
        if (
          window.ReactNativeWebView &&
          window.ReactNativeWebView?.postMessage
        ) {
          console.log('window.ReactNativeWebView exists');
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({ message: 'FAS Client loaded' }),
          );
        } else {
          console.log('window.ReactNativeWebView not exists');
        }
      }
    }
  }, [task, email, token]);

  const sendPostMessage = (message) => {

    try {

      if (typeof message.data !== "undefined") {
        message.fasData = message.data;
      }

      if (window.ReactNativeWebView && window.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify(message),
        );
      }

      if (typeof Meta1FAS !== "undefined") {
        // eslint-disable-next-line no-undef
        Meta1FAS.postMessage(JSON.stringify(message))
      }

      if (window.opener) {
        window.opener.postMessage(JSON.stringify(message), '*')
      }

      if (window.parent) {
        window.parent.postMessage(JSON.stringify(message), '*')
      }

    } catch (e) {
      console.error("Fatal: Failed to send messages, communication channel is broken")
      console.error(e)
    }

    // window.top.postMessage(JSON.stringify(message), '*')
  }

  return (
    <Content style={{ padding: '0 50px' }}>
      <div className={`TaskPage ${task}`}>
        <div
          className="videoContainer"
          style={{
            position: 'relative',
            width: 'calc(100% + 100px)',
            left: -50,
          }}
        >
          {!task || !token || !email ? (
            'loading ...'
          ) : (
            <FASClient
              ref={fasClient}
              token={token}
              username={email}
              task={task}
              activeDeviceId={null}
              onComplete={(token) => {
                console.log('On complete');
                sendPostMessage(
                  {
                    data: {
                      type: 'success',
                      task,
                      message: 'success',
                      token,
                    },
                  },
                );
              }}
              onFailure={(message) => {
                console.log('On failure');
                sendPostMessage(
                  {
                    data: {
                      type: 'success',
                      task,
                      message,
                    },
                  },
                );
              }}
              onCancel={() => {
                console.log('On cancel');
                sendPostMessage(
                  {
                    data: {
                      type: 'cancel',
                      message: 'cancel',
                    },
                  },
                );
              }}
            />
          )}
        </div>
      </div>
    </Content>
  );
};

export default FAS;
