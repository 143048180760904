import { Layout, Menu } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import FASClient from '../components/FASClient';
import { fasEnroll, getFASToken } from '../services/fas';

export const TASK = { REGISTER: 'register', VERIFY: 'verify' };

const Task = () => {
  const { Header, Content } = Layout;
  const fasClient = useRef();
  const { task } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const em = useRef();

  useEffect(() => {
    (async () => {
      const { token } = await getFASToken(email, task);
      setToken(token);
      console.log(token);
    })();
  }, [email, task]);

  useEffect(() => {
    if (token && email) {
      console.log('Loading fas');
      if (fasClient.current) {
        fasClient.current.load();
      }
    }
  }, [token, email]);

  return (
    <>
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">
            <Link to="/fasclient/register?{}">Register</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/fasclient/verify">Verify</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="TaskPage">
          <h1>{task}</h1>

          <input
            type="text"
            // value={email}
            ref={em}
            // onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={(e) => setEmail(em.current.value)}>Connect</button>

          <div
            className="videoContainer"
            style={{
              position: 'relative',
              width: 'calc(100% + 100px)',
              left: -50,
            }}
          >
            {!token || !email ? (
              'loading ...'
            ) : (
              <FASClient
                ref={fasClient}
                token={token}
                username={email}
                task={task}
                activeDeviceId={null}
                onComplete={(token) => {
                  console.log('On complete');
                  if (task === TASK.REGISTER) {
                    fasEnroll(email, 'asdasd', token).then((data) => {
                      console.log('Success enroll ', data);
                    });
                  }
                }}
                onFailure={() => console.log('On failure')}
              />
            )}
          </div>
        </div>
      </Content>
    </>
  );
};

export default Task;
