import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

const ProcessingCanvasComponent = React.forwardRef(
  (
    props = {
      onTrackReady: () => console.log('processing track is ready'),
    },
    ref,
  ) => {
    const canvasRef = useRef();
    const videoRef = useRef();
    const [sendInterval, setSendInterval] = useState(null);
    const [frameRate, setFrameRate] = useState(2);
    const [frameSize, setFrameSize] = useState({ width: 720, height: 480 });
    const [fillColor, setFillColor] = useState([255, 255, 255]);

    const startResampling = (newStream) => {
      console.log('PCC', 'stream updated', newStream);
      if (newStream) {
        console.log('PCC', 'resampling started');
        const canvas = canvasRef.current;
        const video = videoRef.current;
        video.srcObject = newStream;

        const ctx = canvas.getContext('2d');

        if (sendInterval) {
          clearInterval(sendInterval);
        }

        const _sendInterval = setInterval(() => {
          const originalWidth = video.videoWidth;
          const originalHeight = video.videoHeight;

          // Determine whether to crop/pad for each dimension
          const scaleWidth = frameSize.width / originalWidth;
          const scaleHeight = frameSize.height / originalHeight;
          const scale = Math.max(scaleWidth, scaleHeight);

          // Calculate the dimensions to scale to while maintaining aspect ratio
          let scaledWidth = originalWidth * scale;
          let scaledHeight = originalHeight * scale;

          // Check if cropping is needed
          let cropX = 0, cropY = 0;
          if (scaledWidth > frameSize.width) {
            cropX = (scaledWidth - frameSize.width) / 2;
          }
          if (scaledHeight > frameSize.height) {
            cropY = (scaledHeight - frameSize.height) / 2;
          }

          // Clear the canvas
          ctx.fillStyle = `rgb(${fillColor[0]}, ${fillColor[1]}, ${fillColor[2]})`;
          ctx.fillRect(0, 0, frameSize.width, frameSize.height);

          // Draw the image on the canvas, scaled and cropped
          ctx.drawImage(
            video,
            cropX / scale, cropY / scale,  // Start clipping
            frameSize.width / scale, frameSize.height / scale, // Clip width and height
            0, 0,  // Place the image at 0, 0 on the canvas
            frameSize.width, frameSize.height  // Canvas size to draw to
          );

          // ctx.drawImage(video, 0, 0, frameSize.width, frameSize.height);
        }, 1000 / frameRate);

        setSendInterval(_sendInterval);

        const stream = canvas.captureStream(frameRate);
        const track = stream.getTracks()[0];

        if (props.onTrackReady) {
          console.log('PCC', 'calling on track ready');
          props.onTrackReady(track, stream);
        }

        return () => clearInterval(sendInterval);
      }
    };

    useImperativeHandle(ref, () => ({
      updateOriginalStream: (newStream) => {
        startResampling(newStream);
      },
    }));

    return (
      <>
        <video
          ref={videoRef}
          style={{ display: 'block', position: "absolute" }}
          autoPlay
          playsInline
        ></video>
        <canvas
          ref={canvasRef}
          width={frameSize.width}
          height={frameSize.height}
          style={{ display: 'block' }}
        ></canvas>
      </>
    );
  },
);

export default ProcessingCanvasComponent;
