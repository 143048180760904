// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notification-container > .ant-message.ant-message-top {
	position: static !important;
	transform: translateY(10px) !important;
}

ant-message *,
ant-message-top *,
.ant-message-notice-content * {
	animation-duration: 0s !important;
	transition-duration: 0s !important;
}

.btn_refresh {
  position: absolute;
  z-index: 10;
  right: 42px;
  top: 6px;
}

.btn_close {
    position: absolute;
    z-index: 10;
    right: 6px;
    top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/style.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,sCAAsC;AACvC;;AAEA;;;CAGC,iCAAiC;CACjC,kCAAkC;AACnC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,QAAQ;AACV;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,QAAQ;AACZ","sourcesContent":["#notification-container > .ant-message.ant-message-top {\n\tposition: static !important;\n\ttransform: translateY(10px) !important;\n}\n\nant-message *,\nant-message-top *,\n.ant-message-notice-content * {\n\tanimation-duration: 0s !important;\n\ttransition-duration: 0s !important;\n}\n\n.btn_refresh {\n  position: absolute;\n  z-index: 10;\n  right: 42px;\n  top: 6px;\n}\n\n.btn_close {\n    position: absolute;\n    z-index: 10;\n    right: 6px;\n    top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
