import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { ToastContainer } from 'react-toastify';
import FAS from './pages/FAS';
import Task from './pages/Task';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Layout>
        <Routes>
          <Route path="/" element={<FAS />} />
          <Route path="/fasclient/:task" element={<Task />} />
        </Routes>
        <ToastContainer />
      </Layout>
    </Router>
  );
};

export default App;
